<script lang="ts">
import { defineComponent, ref } from 'vue';
import store from '@/store';
import { Error } from '@/models/error';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const credential = ref({ username: '', password: '' });
    const inputType = ref('password');
    const error = ref('');
    const $router = useRouter();

    const loginClicked = async () => {
      error.value = '';
      const response = await store.auth.login(credential.value);

      if (response.data instanceof Error) {
        error.value = response.data.message;
        return;
      }

      $router.push('/');
    };

    const eyeClicked = () => {
      if (inputType.value === 'text') {
        inputType.value = 'password';
      } else {
        inputType.value = 'text';
      }
    };

    return {
      credential,
      inputType,
      eyeClicked,
      loginClicked,
      error,
    };
  },
});
</script>

<template>
  <section :class="$style.component">
    <h2 :class="$style.title">ログイン</h2>
    <p v-if="typeof error === 'string'" :class="$style.error">{{ error }}</p>
    <form :class="$style.login" @submit.prevent action="/" method="GET">
      <label for="username">
        Username:
        <input type="text" id="username" name="username" :class="$style.input" v-model="credential.username" />
        <p v-for="(message, i) in error.username" :key="i" :class="$style.error">{{ message }}</p>
      </label>
      <label for="password">
        Password:
        <p :class="$style.password">
          <input :type="inputType" id="password" name="password" :class="$style.input" v-model="credential.password" />
          <img
            v-if="inputType === 'password'"
            :class="$style.img"
            src="@/assets/img/eye.svg"
            @click="eyeClicked"
            alt="eye"
          />
          <img v-else :class="$style.img" src="@/assets/img/eye-slash.svg" @click="eyeClicked" alt="eye" />
        </p>
        <p v-for="(message, i) in error.password" :key="i" :class="$style.error">{{ message }}</p>
      </label>
      <button :class="$style.login_button" @click="loginClicked">ログイン</button>
    </form>
  </section>
</template>

<style lang="scss" module>
.component {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: $color-light;

  h2 {
    &.title {
      margin: 0 auto;
      text-align: center;
      border-bottom: solid 1px #aaa;
    }
  }

  .error {
    margin: 0;
    color: #dc3232;
  }

  .login {
    display: flex;
    flex-direction: column;

    & > label {
      margin: 8px;
    }

    .input {
      outline: none;
      box-sizing: border-box;
      height: 30px;
      margin: 0 0;
      padding: 8px;
      display: block;
      max-width: 300px;
      width: 100%;
    }

    .password {
      position: relative;
      max-width: 300px;

      .img {
        position: absolute;
        top: 50%;
        right: 15px;
        height: 24px;
        width: 24px;
        transform: translateY(-50%);
        object-fit: contain;
        display: block;
      }
    }

    .login_button {
      outline: none;
      box-sizing: border-box;
      // height: 30px;
      margin: 0 0;
      padding: 8px;
      display: block;
      width: fit-content;
      font-size: 18px;
      line-height: 1;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
