
import { defineComponent, ref } from 'vue';
import store from '@/store';
import { Error } from '@/models/error';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const credential = ref({ username: '', password: '' });
    const inputType = ref('password');
    const error = ref('');
    const $router = useRouter();

    const loginClicked = async () => {
      error.value = '';
      const response = await store.auth.login(credential.value);

      if (response.data instanceof Error) {
        error.value = response.data.message;
        return;
      }

      $router.push('/');
    };

    const eyeClicked = () => {
      if (inputType.value === 'text') {
        inputType.value = 'password';
      } else {
        inputType.value = 'text';
      }
    };

    return {
      credential,
      inputType,
      eyeClicked,
      loginClicked,
      error,
    };
  },
});
